import { render, staticRenderFns } from "./Loader.vue?vue&type=template&id=94521dda&"
import script from "./Loader.js?vue&type=script&lang=js&"
export * from "./Loader.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/mijnmooiestraat.vlaanderen/staging.mijnmooiestraat.vlaanderen/pro-rc.mijnmooiestraat.vlaanderen/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('94521dda')) {
      api.createRecord('94521dda', component.options)
    } else {
      api.reload('94521dda', component.options)
    }
    module.hot.accept("./Loader.vue?vue&type=template&id=94521dda&", function () {
      api.rerender('94521dda', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/Loader/Loader.vue"
export default component.exports