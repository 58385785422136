import _isFunction from 'lodash/isFunction';
import Config from '$root/Config';

import InfoBox from '../InfoBox/InfoBox.vue'
import EditOverlayChildMixin from '$mixins/EditOverlayChildMixin';
import { formatDate } from '$utils/Utils';

export default {
	props: {
		startDate: {
			type: [String, Date],
			required: false,
		},
		overlayTitle: {
			type: String,
			default: 'Datum aanpassen',
		},
		submitName: {
			type: String,
			default: undefined,
		},
		resetValue: {
			type: Boolean,
			default: true,
		},
		enabledDates: {
			type: Array,
			default: () => [],
		},
		infoBox: {
			type: Object,
			default: () => {},
		}
	},

	components: {
		InfoBox
	},

	mixins: [EditOverlayChildMixin],

	mounted() {
		this.showApply();
		this.currentDate = this.startDate || new Date()
	},

	data() {
		return {
			currentDate: null,
		};
	},

	computed: {
		currentValue: {
			cache: false,
			get() {
				return formatDate(this.startDate || new Date(), Config.apiDateFormat);
			},
			set(value) {
				this.currentDate = new Date(value);
			},
		},
	},

	methods: {
		_onApplyClick(e) {
			if (e && _isFunction(e.preventDefault)) {
				e.preventDefault();
			}

			this.$emit('apply', this.currentDate);
			if (this.resetValue) {
				this.currentDate = this.startDate || new Date();
			}
			this.hide();
			this.showApply();
		},
		afterhide() {
			this.$emit('afterhide');
		},
		_onCloseClick() {
			this.$emit('close')
		},
		resetDate() {
			this.currentDate = this.startDate
		},
	},

	watch: {
		startDate(val) {
			if (val) {
				this.currentDate = val
			}
		}
	}
};
