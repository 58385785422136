import _isFunction from 'lodash/isFunction';
import _isNull from 'lodash/isNull';
import _isObject from 'lodash/isObject';
import _isEqual from 'lodash/isEqual';
import _first from 'lodash/first';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _has from 'lodash/has';
import _get from 'lodash/get';
import _map from 'lodash/map';

import RootInjector from '$mixins/RootInjectorMixin';
import WindowResize from '$mixins/window-resize-mixin';
// Way too hard coupled to category selection, needs to be much more generic

const dropdownMinWidth = 256;

export default {
	props: {
		options: {
			type: Array,
			default: () => [],
		},

		value: {
			validator: () => true,
		},

		placeholder: {
			type: String,
			default: 'Choose',
		},

		label: {
			type: String,
			default: 'Label',
		},

		showLabel: {
			type: Boolean,
			default: false,
		},

		customHeader: {
			type: Boolean,
			default: false,
		},

		fluidOnMobile: {
			type: Boolean,
			default: false,
		},

		alignRight: {
			type: Boolean,
			default: false,
		},

		bottomSpace: {
			type: Boolean,
			default: false,
		},

		largeOptions: {
			type: Boolean,
			default: false,
		},

		disableScroll: {
			type: Boolean,
			default: false,
		},

		disableOuterScroll: {
			type: Boolean,
			default: false,
		},

		stickyOptions: {
			type: Boolean,
			default: false,
		},

		hideOnScroll: {
			type: Boolean,
			default: false,
		},

		withoutMinWidth: {
			type: Boolean,
			default: false,
		},

		preventIconDisplay: {
			type: Boolean,
			default: false,
		},

		dropdownWidth: {
			type: Number,
			default: null,
		},

		dropdownAlignPosition: {
			type: String,
			required: false,
			default: 'left',
			validator: (value) => _includes(['left', 'right'], value),
		},

		injectInRoot: {
			type: Boolean,
			default: true,
		},

		boolSelect: {
			type: Boolean,
			default: false,
		},

		model: {
			type: Object,
			required: false,
		},
	},

	mixins: [RootInjector, WindowResize],

	data() {
		return {
			isOpen: false,
			preventStateChange: false,
			isChangingState: false,
			prevList: null,
			focusedItem: null,
			overFlow: false,
			searchString: '',
			searchTimer: false,
			keyboardLocked: false,
			preventJump: true,
			_currentItem: undefined,
			_selectedOptions: undefined,
			_previousItem: undefined,
		};
	},

	mounted() {
		this.currentList = this.options;
		this.$emit('mounted');

		if(this.boolSelect) {
			const activeItemIndex = this.currentList.findIndex((item) => item.value === this.value);
			if (activeItemIndex > -1) {
				this.currentItem = this.currentList[activeItemIndex]
			}
		}

		if (this.currentItem || this.value) {
			this.setActiveOption(this.currentItem || this.value, true);
		}

		if (this.stickyOptions) {
			window.addEventListener('scroll', () => {
				this.updatePositionWhenOpen();
			});
		}
	},

	computed: {
		currentItem: {
			cache: false,
			get() {
				if (!this._currentItem) {
					this._currentItem = this.value;
				}

				return this._currentItem;
			},

			set(value) {
				this._currentItem = value;
			},
		},
		currentList: {
			cache: false,
			get() {
				// need to filter options to only those available in current context (basing on option's permissions)
				let filteredOptions = _filter(this._selectedOptions, checkPermissions);

				// we get first element of currentList and then check whether it has property id
				// if not, we have to map each option and enrich it with index-based id
				if (!_has(_first(filteredOptions), 'id')) {
					filteredOptions = _map(filteredOptions, (option, i) => ({
						...option,
						id: i,
					}));
				}

				return filteredOptions;
			},
			set(value) {
				this._selectedOptions = value;
				this.$forceUpdate();
			},
		},
	},

	methods: {
		updatePositionWhenOpen() {
			if (this.isOpen) {
				this.updateListPosition();
			}
		},

		removeCurrentItem(removePrevious) {
			this.currentItem = undefined;
			if (removePrevious) {
				this._previousItem = undefined;
			}
			this.$forceUpdate();
		},

		open() {
			if (!this.isOpen) {
				this.isOpen = true;
				if (this.hideOnScroll) { 
					window.addEventListener('scroll', this.$data._windowResizeMixin.bindedListener);
				}
				if (this.injectInRoot) {
					this.updateListPosition();
					this.inject_in_root(this.$refs.optionlist);
				}
				this.$nextTick(() => {
					this.updateListHeight();

					if (this.disableOuterScroll) {
						document.querySelector('.page').classList.add('no-scroll');
					}
				});
				// set previous item for quick reset when action is incomplete on close
				this._previousItem = this.currentItem;
			}
		},

		close() {
			if (this.isOpen) {
				this.isOpen = false;

				if (this.injectInRoot) {
					this.remove_from_root(this.$refs.optionlist);
				}

				this.currentList = this.options;

				this.focusedItem = null;

				// set back to previous item if current item still has children
				if (_get(this, 'currentItem.options.length', 0)) {
					this.currentItem = this._previousItem;
				}

				if (this.disableOuterScroll) {
					document.querySelector('.page').classList.remove('no-scroll');
				}
			}
		},

		toggle() {
			if (this.isOpen) {
				this.close();
			} else {
				this.open();
			}
		},

		onHeaderClick(e) {
			if (!e.detail) {
				this.onEnterKey();
			} else {
				changeState.call(this, 'toggle');
			}
		},

		onItemClick(e) {
			const activeOption = this.getOptionByIndex(e.currentTarget.getAttribute('data-option-index'));

			if (activeOption.disabled) {
				if (_isFunction(activeOption.onClick)) {
					activeOption.onClick(this.model);
				}

				return;
			}

			this.setActiveOption(activeOption);

			// need to check whether onClick is actually a function, to make sure it's callable
			if (_isFunction(activeOption.onClick)) {
				activeOption.onClick(this.model);
			}
		},

		getOptions() {
			return this.options;
		},

		getOptionByIndex(index) {
			return this.currentList[index];
		},

		setActiveOption(optionQuery, silent = false) {
			const option = findElement(this.currentList, optionQuery);

			if (option) {
				this.currentItem = optionQuery;

				if (option.options) {
					this.prevList = this.currentList;
					this.currentList = this.currentItem.options;
					this.preventStateChange = true;
				} else {
					this.close();
				}

				if (!silent) {
					this.$emit('input', {
						target: this,
						item: this.currentItem,
					});
				}
			}
		},

		onHeaderClickOutside() {
			changeState.call(this, 'close');
		},

		onListClickOutside(e) {
			if (e.detail) {
				changeState.call(this, 'close');
			}
		},

		onWindowResize() {
			if (this.isOpen) {
				this.updateListPosition();
			}
		},
		
		onWindowScroll() {
			window.removeEventListener('scroll', this.$data._windowResizeMixin.bindedListener);
			this.close();
		},

		updateListPosition() {
			// position and size the list
			const list = this.$refs.optionlist;
			const el = this.$el;
			const elOffset = getElementOffset(el);
			const selectedListWidth = this.dropdownWidth || dropdownMinWidth;
			const listWidth = Math.max(selectedListWidth, el.clientWidth);

			const listLeftPosition = Math.max(elOffset.left - Math.abs(listWidth - el.clientWidth), 5);
			const listRightPosition = elOffset.right - el.clientWidth;

			list.style.top = `${elOffset.top + el.clientHeight}px`;
			list.style.left = `${this.dropdownAlignPosition === 'left' ? listLeftPosition : listRightPosition}px`;
			list.style.width = `${listWidth}px`;
		},

		updateListHeight() {
			if (!this.disableScroll) {
				const list = this.$refs.optionlist;

				if (list.clientHeight > window.innerHeight / 2.5) {
					list.style.maxHeight = `${window.innerHeight / 2.5}px`;
					list.style.overflowY = 'scroll';
				}
			}
		},

		initScrollBar() {
			const { scrollBar } = this.$refs;
			const list = this.$refs.optionlistInner;
			scrollBar.style.height = `${list.clientHeight}px`;
			scrollBar.style.top = '0';

			this.$refs.optionlist.addEventListener('scroll', this.$blm.add('updateScrollBar', this, this.updateScrollBar));
		},

		updateScrollBar() {
			const indicator = this.$refs.scrollBarIndicator;
			const list = this.$refs.optionlist;
			const listMax = list.scrollHeight - list.clientHeight;
			const indicatorMax = 100;

			if (list.scrollTop <= listMax) {
				const val = mapRange(list.scrollTop, 0, listMax, 0, indicatorMax);
				indicator.style.top = `${val}%`;
			}
		},

		changeFocusedIndex(dir) {
			let index = this.focusedItem ? this.currentList.indexOf(this.focusedItem) : -1;
			dir ? index-- : index++;

			if (index < 0) {
				index = this.currentList.length - 1;
			}

			if (index > this.currentList.length - 1) {
				index = 0;
			}

			this.focusItemById(this.currentList[index].id);
		},

		onInput(e) {
			if (this.keyboardLocked) {
				return;
			}

			this.preventJump = false;

			if (_includes([13, 37, 38, 39, 40], e.keyCode)) {
				this.searchString = '';

				switch (e.keyCode) {
					case 38: {
						this.changeFocusedIndex(true);
						break;
					}
					case 40: {
						this.changeFocusedIndex(false);
						break;
					}
					case 13: {
						this.onEnterKey();
						break;
					}
					case 39: {
						this.onEnterKey();
						break;
					}
					case 37: {
						if (this.prevList) {
							this.currentList = this.prevList;
						}
						break;
					}
					default:
				}
			} else {
				if (this.searchTimer) {
					window.clearTimeout(this.searchTimer);
				}

				this.searchTimer = window.setTimeout(() => {
					this.searchString = '';
				}, 1000);

				this.searchString += e.key.toLowerCase();
				// testing focusing
				let result = false;
				const resultEl = [...this.$refs.optionlist.querySelectorAll('.advancedSelect-item')].filter((el) => {
					return el.innerHTML
						.trim()
						.toLowerCase()
						.startsWith(this.searchString);
				})[0];

				if (resultEl) {
					result = this.currentList.filter((option) => {
						return option.id === resultEl.dataset.optionId;
					})[0];
				}

				if (!result) {
					this.searchString = '';
					window.clearTimeout(this.searchTimer);
					this.keyboardLocked = true;
					return;
				}
				this.focusedItem = result;
			}
			// lock keyboard as we use keydown
			this.keyboardLocked = true;
		},

		focusItemById(id, preventJump = false) {
			this.preventJump = preventJump;
			const result = this.currentList.filter((option) => {
				return option.id === id;
			})[0];
			this.focusedItem = result;
		},

		onEnterKey() {
			if (this.focusedItem && this.focusedItem.options) {
				this.setActiveOption(this.focusedItem);
				this.searchString = '';
			} else if (this.focusedItem) {
				this.setActiveOption(this.focusedItem);
			}
		},

		onKeyUp() {
			this.keyboardLocked = false;
		},
	},

	beforeDestroy() {
		window.removeEventListener('scroll', () => {
			this.updatePositionWhenOpen();
		});
		this.close();
	},

	watch: {
		focusedItem() {
			if (this.preventJump) {
				return;
			}

			const focusedEl = [...this.$refs.optionlist.querySelectorAll('.advancedSelect-item')].filter((el) => {
				return this.focusedItem.id == el.dataset.optionId;
			})[0];

			if (
				focusedEl.offsetTop + focusedEl.offsetHeight > this.$refs.optionlist.scrollTop + this.$refs.optionlist.offsetHeight ||
				focusedEl.offsetTop < this.$refs.optionlist.scrollTop
			) {
				this.$refs.optionlist.scrollTo(0, focusedEl.offsetTop);
			}
		},

		options(newOptions, oldOptions) {
			if (!_isEqual(newOptions, oldOptions)) {
				this.currentList = newOptions;
			}
		},
	},
};

function changeState(type) {
	// block if changing
	if (this.isChangingState) {
		return;
	}

	// grab action (using switch to avoid uglification issues)
	let action;
	switch (type) {
		case 'open': {
			action = this.open.bind(this);
			break;
		}
		case 'close': {
			action = this.close.bind(this);
			break;
		}
		case 'toggle': {
			action = this.toggle.bind(this);
			break;
		}
	}

	// set changing state
	this.isChangingState = true;
	setTimeout(() => {
		if (!this.preventStateChange) {
			action();
		}

		this.isChangingState = false;
		this.preventStateChange = false;
	}, 50);
}

function getElementOffset(el) {
	const rect = el.getBoundingClientRect();

	return {
		top: rect.top + window.pageYOffset,
		left: rect.left + window.pageXOffset,
		right: rect.right + window.pageXOffset,
	};
}

function findElement(options, target) {
	for (let i = 0; i < options.length; i++) {
		let option = options[i];
		let found = true;

		for (const p in target) {
			// extract values first, else getters will be compared instead of thier values
			const v1 = option[p];
			const v2 = target[p];

			// don't compare methods
			if (_isFunction(v1)) {
				continue;
			}

			// check object only if Object constructor was used, else do data type check
			// null check on v2 is not necessary
			if (!_isNull(v1)) {
				if (_isObject(v1) && _isObject(v2)) {
					if (v1.constructor.name === 'Object') {
						if (!findElement([v1], v2)) {
							found = false;
							break;
						} else {
							continue;
						}
					}
				} else if (v1 !== v2) {
					found = false;
					break;
				}
			}
		}

		// if multi level
		if (!found && option.options) {
			option = findElement(option.options, target) || null;
			found = !!option;
		}

		// check if found
		if (found) {
			return option;
		}
	}

	return undefined;
}

function mapRange(num, inMin, inMax, outMin, outMax) {
	return ((num - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

function checkPermissions(option) {
	// the option is restricted if we pass requiredPermissions property which is a function
	const isRestricted = _isFunction(option.requiredPermissions);

	// we allow to display option if it's not restricted or if it's restricted and
	// requiredPermissions are avaiable (function returns true)
	return !isRestricted || option.requiredPermissions();
}
